<template>
  <div class="auth-wrapper auth-v1">
    <b-row class="auth-inner m-0">
      <!-- Forgot password-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            style="text-align:center; margin-top:-50px;"
          >
            <b-img
              style="width:150px;"
              :style="{ zoom: (partner === 'ladestellenbonus') ? '1.5' : ''}"
              :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
            />
            <br><br><br><br>
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Einmalpasswort zusenden
          </b-card-title>
          <b-card-text class="mb-2">
            Geben Sie Ihre registrierte E-Mailadresse ein und wir schicken Ihnen einen sicheren Link mit dem Sie sich anmelden können.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-alert
                v-if="showAlert"
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  Fehler
                </h4>
                <div class="alert-body">
                  <span>{{ alertText }}</span>
                </div>
              </b-alert>

              <b-alert
                v-if="showSuccess"
                :variant="partnerData.colorScheme"
                show
              >
                <h4 class="alert-heading">
                  Erfolg
                </h4>
                <div class="alert-body">
                  <span>Wir haben ihnen eine E-Mail mit einem Link und einer Erklärung geschickt, damit Sie sich anmelden können. </span>
                </div>
              </b-alert>
              <b-button
                v-if="showSuccess == false"
                type="submit"
                :variant="partnerData.colorScheme"
                block
              >
                <b-spinner
                  v-if="showLoading"
                  small
                />
                Einmalpasswort zusenden
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link
              :to="{name:'login'}"
              :style="getHrefStyle()"
            >
              <feather-icon icon="ChevronLeftIcon" /> zurück zur Anmeldung
            </b-link>
          </p>
        </b-col>
        <div
          style="position: absolute; bottom: 0; right: 0px; font-size:12px; color:#5e587 !important;"
        >
          <b-button
            :to="{name:'impressum'}"
            variant="flat-secondary"
            size="sm"
          >
            Impressum
          </b-button>
        </div>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
//  import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import axios from '@axios'
import router from '@/router'
import store from '@/store/index'
import errorMsg from '../../../globalErrorMessages'

localize('de')
export default {
  components: {
    // VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BSpinner,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      showAlert: false,
      showSuccess: false,
      showLoading: false,
      alertText: '',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.showSuccess = false
          this.showAlert = false
          this.showLoading = true
          axios
            .post('/auth/otp/request', { email: this.userEmail })
            .then(response => {
              if (response.status === 200) {
                this.showSuccess = true
                this.showLoading = false
              }
            })
            .catch(e => {
              if (e.response.status === 404) {
                this.alertText = 'Die E-Mail-Adresse, die Sie eingegeben haben, ist keinem aktiven Ladestellenbonus.at Konto zugeordnet. Prüfen Sie Ihre Angaben auf Tippfehler und versuchen Sie es mit einer anderen E-Mail-Adresse.'
              } else {
                this.alertText = errorMsg.errorMsg.serverOffline
              }
              this.showAlert = true
              this.showLoading = false
            })
        }
      })
    },
  },
  setup() {
    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    document.title = partnerData.value.htmlPageTitle
    document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`

    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    if (typeof (window.$cookies.get('partner')) !== 'undefined') {
      backgroundImage = require(`@/assets/images/partner/${partner.value}/backgorund/auth.jpg`)
      appIdElem.style.backgroundImage = `url('${backgroundImage}')`

      if ((window.$cookies.get('partner') === 'raiffeisen-leasing_genol') || (window.$cookies.get('partner') === 'raiffeisen_genol') || (window.$cookies.get('partner') === 'emobil-link')) {
        appIdElem.style.backgroundPosition = 'top right'
      }
    }

    // fallback
    if (partnerData.value === null) {
      router.push('/partner/ladestellenbonus/login')
    }

    return {
      partner,
      partnerData,
      getHrefStyle,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
